import { apiGetAuth, apiPostAuth, apiPatchAuth } from '@/utils/api'
import { API_PROMO_MANAGEMENT, API_CONSUMER, API_ORION_PRODUCT } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    promo: { data: [] },
    detail_promo: { data: [] },
    consumers: { data: [] },
    data_modal_add_edit: {},
    data_bulk_promo: [],
    bulk_status: '',
    products: {},
    stores: {},
    meta_filter: {},
    detail_consumer: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_PROMO (state, payload) {
    state.promo = payload
  },
  SET_DETAIL_PROMO (state, payload) {
    state.detail_promo = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_BULK_PROMO(state, payload) {
    state.data_bulk_promo = payload
  },
  SET_BULK_STATUS(state, payload) {
    state.bulk_status = payload
  },
  SET_CONSUMERS (state, payload) {
    state.consumers = payload
  },
  SET_PRODUCT (state, payload) {
    state.products = payload
  },
  SET_STORE (state, payload) {
    state.stores = payload
  },
  SET_DETAIL_CONSUMER (state, payload) {
    state.detail_consumer = payload
  }
}

const actions = {
  async getDetailConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.DETAIL_CONSUMER(payload))
      context.commit('SET_DETAIL_CONSUMER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CONSUMER, payload)
      if(response.data.data){
        context.commit('SET_CONSUMERS', response.data.data)
      } else {
        context.commit('SET_CONSUMERS', [])
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListPromo (context, payload) {
    try{
      const response = await apiGetAuth(API_PROMO_MANAGEMENT.LIST_PROMO_MANAGEMENT, payload)
      context.commit('SET_PROMO', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetailPromo (context, payload) {
    try {
      const response = await apiGetAuth(API_PROMO_MANAGEMENT.DETAIL_PROMO_MANAGEMENT(payload))
      context.commit('SET_DETAIL_PROMO', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updatePromo (context, payload) {
    try {
      const response = await apiPatchAuth(API_PROMO_MANAGEMENT.UPDATE_PROMO_MANAGEMENT(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async createPromo (context, payload) {
    try {
      const response = await apiPostAuth(API_PROMO_MANAGEMENT.CREATE_PROMO_MANAGEMENT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async uploadBulkPromo (context, payload) {
    try {
      const response = await apiPostAuth(API_PROMO_MANAGEMENT.UPLOAD_BULK_PROMO_MANAGEMENT, payload)
      context.commit('SET_BULK_PROMO', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async confirmBulkPromo (context, payload) {
    try {
      const response = await apiPostAuth(API_PROMO_MANAGEMENT.CONFIRM_BULK_PROMO_MANAGEMENT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async statusBulkPromo () {
    try {
      const response = await apiGetAuth(API_PROMO_MANAGEMENT.STATUS_BULK_PROMO_MANAGEMENT)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_ORION_PRODUCT.LIST_PRODUCT, payload)
      if(response.data.data){
        context.commit('SET_PRODUCT', response.data.data)
      } else {
        context.commit('SET_PRODUCT', [])
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_PROMO_MANAGEMENT.LIST_STORE, payload)
      if(response.data.data){
        context.commit('SET_STORE', response.data.data)
      } else {
        context.commit('SET_STORE', [])
      }
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_promo_management (state) {
    return state.promo
  },
  detail_promo_management (state) {
    return state.detail_promo
  },
  list_bulk_promo (state){
    return state.data_bulk_promo
  },
  get_bulk_status (state){
    return state.bulk_status
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_list_consumer (state) {
    return state.consumers
  },
  get_detail_consumer (state){
    return state.detail_consumer
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_list_product (state) {
    return state.products
  },
  get_list_store (state) {
    return state.stores
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
